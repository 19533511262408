import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";

import { GithubRepoNamingProps } from "./schema";
import { useEffect, useMemo, useState } from "react";
import { GithubRepoNameState } from "./types";
import { generateName, parseName } from "./helpers";
import {
  projectNames,
  types,
  domains,
  cloudProviders,
  languages,
  manifestTypes,
} from "./types";

export const GithubRepoNaming = (props: GithubRepoNamingProps) => {
  const { uiSchema, onChange, rawErrors, formData } = props;
  const [state, setState] = useState<GithubRepoNameState>(
    parseName(formData as string),
  );

  const allowedProjectNames = useMemo(
    () => uiSchema?.["ui:options"]?.allowedProjectNames ?? projectNames,
    [uiSchema],
  );
  const allowedTypes = useMemo(
    () => uiSchema?.["ui:options"]?.allowedTypes ?? types,
    [uiSchema],
  );
  const allowedDomains = useMemo(
    () => (uiSchema?.["ui:options"]?.allowedDomains as string[]) ?? domains,
    [uiSchema],
  );
  const allowedLanguages = useMemo(
    () => uiSchema?.["ui:options"]?.allowedLanguages ?? languages,
    [uiSchema],
  );
  const allowedCloudProviders = useMemo(
    () => uiSchema?.["ui:options"]?.allowedCloudProviders ?? cloudProviders,
    [uiSchema],
  );
  const allowedManyfestType = useMemo(
    () => uiSchema?.["ui:options"]?.manifestTypes ?? manifestTypes,
    [uiSchema],
  );

  const {
    projectName,
    type,
    domain,
    language,
    cloudProvider,
    componentName,
    manifestType,
  } = props;

  useEffect(() => {
    onChange(generateName(state));
  }, [state, onChange]);

  const handleAutocompleteChange =
    (field: keyof GithubRepoNameState) => (_event: any, value: any) => {
      setState((prevState) => ({ ...prevState, [field]: value }));
    };

  return (
    <>
      <FormControl
        margin="normal"
        required={true}
        error={rawErrors?.length > 0}
      >
        <InputLabel htmlFor="componentName">Component Name</InputLabel>
        <Input
          id="componentName"
          value={componentName}
          aria-describedby="projectComponentName"
          onChange={(e) =>
            setState((prevState) => ({
              ...prevState,
              componentName: e.target.value,
            }))
          }
        />
        <FormHelperText id="ComponentName">
          Name of the component to create
        </FormHelperText>
      </FormControl>
      <FormControl>
        <Autocomplete
          options={allowedProjectNames}
          value={projectName}
          renderInput={(params) => (
            <TextField {...params} label={"Project Name"} required={true} />
          )}
          onInputChange={handleAutocompleteChange("projectName")}
        />
        <FormHelperText id="ProjectName">
          Select the project to be part of
        </FormHelperText>
      </FormControl>
      <FormControl>
        <Autocomplete
          options={allowedDomains}
          value={domain}
          renderInput={(params) => (
            <TextField {...params} label={"Customer Name or Domain"} />
          )}
          onInputChange={handleAutocompleteChange("domain")}
        />
        <FormHelperText id="Domain">
          Select the project to be part of
        </FormHelperText>
      </FormControl>

      <FormControl>
        <Autocomplete
          options={allowedLanguages}
          value={language}
          renderInput={(params) => (
            <TextField {...params} label={"Main language of the project"} />
          )}
          onInputChange={handleAutocompleteChange("language")}
        />
        <FormHelperText id="Language">
          Select the Language of the repository
        </FormHelperText>
      </FormControl>

      <FormControl>
        <Autocomplete
          options={allowedCloudProviders}
          value={cloudProvider}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Cloud Provider related to the repository"}
            />
          )}
          onInputChange={handleAutocompleteChange("cloudProvider")}
        />
        <FormHelperText id="CloudProvider">
          Select the Cloud Provider Used
        </FormHelperText>
      </FormControl>

      <FormControl>
        <Autocomplete
          options={allowedTypes}
          value={type}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Type of the repository"}
              required={true}
            />
          )}
          onInputChange={handleAutocompleteChange("type")}
        />
        <FormHelperText id="Type">Select the type of repository</FormHelperText>
      </FormControl>

      <FormControl>
        <Autocomplete
          options={allowedManyfestType}
          value={manifestType}
          renderInput={(params) => (
            <TextField {...params} label={"Manifest Type of repository"} />
          )}
          onInputChange={handleAutocompleteChange("manifestType")}
        />
        <FormHelperText id="ManifestType">
          Select the manifest type of repository
        </FormHelperText>
      </FormControl>
    </>
  );
};
