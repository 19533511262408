import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === "dark";

  return {
    jiraIcon: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    keyLink: {
      fontWeight: "bold",
      display: "inline-block",
      color: isDarkMode ? "#90CAF9" : "#1976D2",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    statusBadge: {
      borderRadius: "8px",
      padding: "2px 4px",
      fontWeight: "bold",
      display: "inline-block",
    },
    priorityIcon: {
      width: 22,
      height: 22,
      cursor: "pointer",
    },
    avatar: {
      width: 28,
      height: 28,
      cursor: "pointer",
    },
  };
});

export default useStyles;
