import { makeFieldSchema } from "../utils";

export const GithubRepoNamingFieldSchema = makeFieldSchema({
  output: (z) => z.string(),
  uiOptions: (z) =>
    z.object({
      allowedProjectNames: z
        .array(z.string())
        .describe("Indicates the project that the repository belongs to"),
      allowedTypes: z.array(z.string()).describe("Type of repository"),
      alloweDomains: z.array(z.string()).optional(),
      allowedLanguages: z.array(z.string()).optional(),
      allowedCloudProviders: z.array(z.string()).optional(),
      manifestTypes: z.array(z.string()).optional(),
    }),
});
export const GithubRepoNamingSchema = GithubRepoNamingFieldSchema.schema;
export const GithubRepoNamingUiOptions =
  GithubRepoNamingFieldSchema.uiOptionsType;
export type GithubRepoNamingProps = typeof GithubRepoNamingFieldSchema.type;
