import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  infoCard: {
    marginBottom: theme.spacing(3),
    "& + .MuiAlert-root": {
      marginTop: theme.spacing(3),
    },
  },
  barContainer: {
    height: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: "4px",
    backgroundColor: "transparent",
    overflow: "hidden",
  },
  bar: {
    height: "100%",
    position: "relative",
  },
  languageDot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    marginRight: theme.spacing(1),
    display: "inline-block",
  },
  label: {
    color: "inherit",
  },
}));

export default useStyles;
