import { EntitySonarQubeCard } from "@backstage-community/plugin-sonarqube";
import { Grid } from "@material-ui/core";
import { MendSecurityCard } from "../../components/mend";

export const scoreCardContent = (
  <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
      <EntitySonarQubeCard variant="gridItem" />
    </Grid>
    <Grid item xs={12} md={6}>
      <MendSecurityCard />
    </Grid>
  </Grid>
);
