import { useEffect, useState } from "react";
import { useApi } from "@backstage/core-plugin-api";
import { catalogApiRef } from "@backstage/plugin-catalog-react";
import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  SelectChangeEvent,
} from "@mui/material";

interface AwsAccountFilterProps {
  selectedAccount: string;
  setSelectedAccount: (account: string) => void;
}

export const AwsAccountFilter: React.FC<AwsAccountFilterProps> = ({
  selectedAccount,
  setSelectedAccount,
}) => {
  const catalogApi = useApi(catalogApiRef);

  interface AwsAccountEntity {
    metadata: {
      name: string;
    };
  }

  const [awsAccounts, setAwsAccounts] = useState<AwsAccountEntity[]>([]);

  useEffect(() => {
    const fetchAwsAccounts = async () => {
      try {
        const response = await catalogApi.getEntities({
          filter: { kind: "Resource", "spec.type": "aws-account" },
        });
        const accounts = response.items as AwsAccountEntity[];

        if (!accounts || accounts.length === 0) {
          console.error(
            "No AWS accounts found. This might be due to missing configuration or no accounts being registered.",
          );
        }
        setAwsAccounts((response.items as AwsAccountEntity[]) || []);
      } catch (error) {
        console.error("Failed to fetch AWS accounts:", error);
      }
    };
    fetchAwsAccounts();
  }, [catalogApi]);

  const handleAccountChange = (event: SelectChangeEvent<string>) => {
    setSelectedAccount(event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <Typography variant="h6" style={{ marginBottom: "8px" }}>
        AWS Account
      </Typography>
      <Select
        value={selectedAccount}
        onChange={handleAccountChange}
        displayEmpty
      >
        <MenuItem value="all">All</MenuItem>
        {awsAccounts.map((account) => (
          <MenuItem key={account.metadata.name} value={account.metadata.name}>
            {account.metadata.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
