import { MouseEvent, useState } from "react";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { GridColDef } from "@mui/x-data-grid";
import { CustomDataGrid } from "../../../shared/CustomDataGrid/CustomDataGrid";
import { AwsAccountFilter } from "./AwsAccountFilter";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
);

// Mock Data for AWS Dashboard
const awsData = {
  spendingOverview: { netSpend: 50104, saved: 20062, pendingSavings: 5000 },
  services: [
    {
      name: "RDS",
      costs: [200, 300, 400, 500, 600, 700, 650],
      color: "#36a2eb",
    },
    {
      name: "EC2",
      costs: [150, 250, 350, 450, 550, 650, 600],
      color: "#4bc0c0",
    },
    {
      name: "ElastiCache",
      costs: [100, 200, 300, 400, 500, 600, 550],
      color: "#ff6384",
    },
    {
      name: "CloudFront",
      costs: [50, 100, 150, 200, 250, 300, 250],
      color: "#ff9f40",
    },
    {
      name: "CloudWatch",
      costs: [25, 50, 75, 100, 125, 150, 100],
      color: "#9966ff",
    },
  ],
};

export const AwsDashboard = () => {
  const [timeRange, setTimeRange] = useState("last7days");
  const [selectedAccount, setSelectedAccount] = useState<string>("all");
  const [selectedRegion, setSelectedRegion] = useState<string>("eu-west-1");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const getDynamicData = (granularity: "daily" | "monthly", range: number) => {
    const today = new Date();

    const generateLabels = () => {
      const labels: string[] = [];
      for (let i = 0; i < range; i++) {
        if (granularity === "daily") {
          const date = new Date(today.getTime() - i * 24 * 60 * 60 * 1000);
          const formattedDate = date.toLocaleDateString("default", {
            month: "short",
            day: "2-digit",
          });
          labels.unshift(formattedDate);
        } else if (granularity === "monthly") {
          const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
          const formattedDate = date.toLocaleDateString("default", {
            month: "short",
            year: "numeric",
          });
          labels.unshift(formattedDate);
        }
      }
      return labels;
    };

    const generateData = () => {
      const data = awsData.services.map((service) => {
        const costs = service.costs.slice(0, range); // Adjust cost range
        return costs;
      });
      return data;
    };

    return {
      labels: generateLabels(),
      data: generateData(),
    };
  };

  const { labels, data } = (() => {
    switch (timeRange) {
      case "last7days":
        return getDynamicData("daily", 7);
      case "last30days":
        return getDynamicData("daily", 30);
      case "last90days":
        return getDynamicData("daily", 90);
      case "lastYear":
        return getDynamicData("monthly", 12);
      default:
        return getDynamicData("monthly", 12);
    }
  })();

  const handleTimeRangeChange = (
    _event: MouseEvent<HTMLElement>,
    newRange: string | null,
  ) => {
    if (newRange !== null) {
      setTimeRange(newRange);
    }
  };

  const handleRegionChange = (event: SelectChangeEvent) => {
    setSelectedRegion(event.target.value);
  };

  const columns: GridColDef[] = [
    { field: "name", headerName: "Service", flex: 1 },
    {
      field: "totalCost",
      headerName: "Total Cost ($)",
      flex: 1,
      type: "number",
    },
  ];

  const rows = awsData.services.map((service, index) => {
    const totalCost = service.costs.reduce((sum, cost) => sum + cost, 0);
    const daysCount =
      timeRange === "last7days"
        ? 7
        : timeRange === "last30days"
          ? 30
          : timeRange === "last90days"
            ? 90
            : 365;

    return {
      id: index,
      name: service.name,
      totalCost: totalCost.toFixed(2),
      costPerDay: (totalCost / daysCount).toFixed(2),
    };
  });

  return (
    <div>
      <Grid container spacing={3} style={{ marginBottom: "20px" }}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={3}>
              <AwsAccountFilter
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
              />
            </Grid>

            <Grid item xs={6} md={3}>
              <FormControl fullWidth size="small">
                <Typography variant="h6" style={{ marginBottom: "8px" }}>
                  AWS Region
                </Typography>
                <Select value={selectedRegion} onChange={handleRegionChange}>
                  <MenuItem value="eu-west-1">eu-west-1</MenuItem>
                  <MenuItem value="eu-central-1">eu-central-1</MenuItem>
                  <MenuItem value="us-east-1">us-east-1</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <Typography variant="h6" style={{ marginBottom: "8px" }}>
              Time Range
            </Typography>
            <ToggleButtonGroup
              value={timeRange}
              exclusive
              onChange={handleTimeRangeChange}
              aria-label="time range"
              style={{ display: "flex" }}
              size="small"
            >
              <ToggleButton value="last7days">Last 7 Days</ToggleButton>
              <ToggleButton value="last30days">Last 30 Days</ToggleButton>
              <ToggleButton value="last90days">Last 90 Days</ToggleButton>
              <ToggleButton value="lastYear">Last Year</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={2}>
          <Card
            style={{
              marginBottom: "16px",
              borderRadius: "12px",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            }}
          >
            <CardContent style={{ padding: "16px", textAlign: "center" }}>
              <Typography
                variant="h6"
                style={{ marginBottom: "8px", fontWeight: "bold" }}
              >
                Total Cost
              </Typography>
              <Typography variant="h2" style={{ color: "#4caf50" }}>
                ${awsData.spendingOverview.netSpend.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>

          <Card
            style={{
              marginBottom: "16px",
              borderRadius: "12px",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            }}
          >
            <CardContent style={{ padding: "16px", textAlign: "center" }}>
              <Typography
                variant="h6"
                style={{ marginBottom: "8px", fontWeight: "bold" }}
              >
                Forecasted Cost
              </Typography>
              <Typography variant="h2" style={{ color: "#ff6384" }}>
                ${awsData.spendingOverview.netSpend.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Spending by Service</Typography>
              <Bar
                data={{
                  labels,
                  datasets: awsData.services.map((service, index) => ({
                    label: service.name,
                    data: data[index],
                    backgroundColor: service.color,
                    borderWidth: 1,
                    borderColor: "rgba(0,0,0,0.1)",
                  })),
                }}
                options={{
                  plugins: {
                    legend: {
                      display: true,
                      position: "bottom",
                      labels: {
                        boxWidth: 20,
                      },
                    },
                  },
                  responsive: true,
                  scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true,
                      title: {
                        display: true,
                        text: "Costs ($)",
                      },
                    },
                  },
                }}
                height={100}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Trending Cost</Typography>
              <Line
                data={{
                  labels,
                  datasets: [
                    {
                      label: "Cost Trend",
                      data: [8000, 10000, 15000, 20000, 25000, 20000, 19000],
                      backgroundColor: ["rgb(255, 99, 132)"],
                      borderColor: ["rgb(255, 99, 132)"],
                      borderWidth: 2,
                      tension: 0.4,
                      pointBackgroundColor: "rgb(255, 99, 132)",
                      pointRadius: 5,
                    },
                  ],
                }}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Cost Breakdown</Typography>
              <CustomDataGrid
                rows={rows}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default AwsDashboard;
