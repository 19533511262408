import {
  CustomFieldExtensionSchema,
  FieldExtensionComponentProps,
} from "@backstage/plugin-scaffolder-react";
import zodToJsonSchema from "zod-to-json-schema";
import { JSONSchema7 } from "json-schema";
import { z } from "zod";

export function makeFieldSchema<
  TReturnType extends z.ZodType,
  TUiOptions extends z.ZodType,
>(options: {
  output: (zImpl: typeof z) => TReturnType;
  uiOptions?: (zImpl: typeof z) => TUiOptions;
}): FieldSchema<z.output<TReturnType>, z.output<TUiOptions>> {
  const { output, uiOptions } = options;
  return {
    TProps: undefined as any,
    schema: {
      returnValue: zodToJsonSchema(output(z)) as JSONSchema7,
      uiOptions: uiOptions && (zodToJsonSchema(uiOptions(z)) as JSONSchema7),
    },

    type: undefined as any,
    uiOptionsType: undefined as any,
  };
}

export interface FieldSchema<TReturn, TUiOptions> {
  readonly type: FieldExtensionComponentProps<TReturn, TUiOptions>;
  readonly uiOptionsType: TUiOptions;
  readonly schema: CustomFieldExtensionSchema;
  readonly TProps: FieldExtensionComponentProps<TReturn, TUiOptions>;
}
