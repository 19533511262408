export const GetFilePathFromUrl = (url: string): string => {
  const urlParts = url.split("/");
  if (!urlParts.includes("-")) {
    console.log(`Invalid resource URL: ${url}, missing "-" separator`);
  }
  // This is super unreliable, depends very heavily on the URL structure
  return urlParts.slice(urlParts.indexOf("-") + 3, urlParts.length).join("/");
};

export const GetProjectNameFromUrl = (url: string): string => {
  const urlParts = url.split("/");
  if (!urlParts.includes("-")) {
    console.log(`Invalid project URL: ${url}, missing "-" separator`);
  }
  return urlParts[urlParts.indexOf("-") - 1];
};

export const GetProjectIdFromName = async (
  gitlab: any,
  projectName: string,
): Promise<number> => {
  try {
    const projects = await gitlab.Search.all("projects", projectName);
    if (projects.length === 0) {
      console.error(`Project with name '${projectName}' not found.`);
      return -1;
    }

    if (projects.length > 1) {
      console.error(`Multiple projects found with name '${projectName}'.`);
      return -1;
    }
    return projects[0].id;
  } catch (error) {
    console.error(
      `Failed to resolve project id for project name '${projectName}': ${error}`,
    );
    return -1;
  }
};

export const GetProject = async (
  gitlab: any,
  projectId: number,
): Promise<number> => {
  try {
    const project = await gitlab.Projects.show(projectId);
    return project.id;
  } catch (error) {
    console.error(
      `Failed to resolve project id for project ID '${projectId}': ${error}`,
    );
    return -1;
  }
};

export const CreateBranch = async (
  gitlab: any,
  projectId: number,
  branchName: string,
): Promise<"success" | "unauthorized" | "error"> => {
  try {
    const response = await gitlab.Branches.create(
      projectId,
      branchName,
      "master",
    );
    if (response.status === 401) {
      return "unauthorized";
    }
    console.log(`Branch '${branchName}' created successfully.`);
    return "success";
  } catch (error) {
    console.error(`Failed to create branch '${branchName}': ${error}`);
    return "error";
  }
};

export const DeleteBranch = async (
  gitlab: any,
  projectId: number,
  branchName: string,
): Promise<"success" | "unauthorized" | "error"> => {
  try {
    await gitlab.Branches.remove(projectId, branchName);
    console.log(`Branch '${branchName}' deleted successfully.`);
    return "success";
  } catch (error) {
    console.error(`Failed to delete branch '${branchName}': ${error}`);
    return "error";
  }
};

export const CreateCommit = async (
  gitlab: any,
  projectId: number,
  branchName: string,
  resourceName: string,
  filePath: string,
  fileContent: string,
): Promise<"success" | "unauthorized" | "error"> => {
  const message = "[Selfservice BOT] Creating: " + resourceName;
  let action = (...args: any[]) => gitlab.RepositoryFiles.edit(...args);
  let file = null;

  try {
    file = await gitlab.RepositoryFiles.show(projectId, filePath, branchName);
  } catch {
    console.log(`File'${filePath}' does not exist in branch: '${branchName}'`);
    action = (...args: any[]) => gitlab.RepositoryFiles.create(...args);
  }

  try {
    if (file !== null) {
      const content = Buffer.from(file.content, "base64").toString();
      if (content === fileContent) {
        console.log(`File '${filePath}' already exists with the same content.`);
        return "error";
      }
    }
    console.log(projectId, filePath, branchName, fileContent, message);
    const response = await action(
      projectId,
      filePath,
      branchName,
      fileContent,
      message,
    );
    if (response.status === 401) {
      return "unauthorized";
    }
    console.log(`Commit created successfully on branch '${branchName}'.`);
    return "success";
  } catch (error) {
    console.log(error);
    return "error";
  }
};

export const CreateOrUpdateMergeRequest = async (
  gitlab: any,
  projectId: number,
  branchName: string,
  title: string,
): Promise<{ id: number; link: string }> => {
  try {
    const mergeRequests = await gitlab.MergeRequests.all(projectId, {
      source_branch: branchName,
      target_branch: "master",
      state: "opened",
    });
    //TODO - refine this to make sure that the MR is only updated if the source branch is the same
    if (mergeRequests.length > 0 && mergeRequests[0].state === "opened") {
      const mergeRequest = mergeRequests[0];
      const mr = await gitlab.MergeRequests.edit(projectId, mergeRequest.iid, {
        title,
        description:
          "Automatically UPDATED by the TowerBridge Self-service BOT",
      });
      console.log(
        `Merge request updated successfully for branch '${branchName}'.`,
      );
      return { id: mr.iid, link: mr.web_url };
    } else {
      const mr = await gitlab.MergeRequests.create(
        projectId,
        branchName,
        "master",
        title,
        "Automatically CREATED by the TowerBridge Self-service BOT",
      );
      console.log(
        `Merge request created successfully for branch '${branchName}'.`,
      );
      return { id: mr.iid, link: mr.web_url };
    }
  } catch (error) {
    console.error(`Failed to create or update merge request: ${error}`);
    return { id: -1, link: "" };
  }
};

export const CloseMergeRequest = async (
  gitlab: any,
  projectId: number,
  mrId: number,
): Promise<"success" | "unauthorized" | "error"> => {
  try {
    const mr = await gitlab.MergeRequests.edit(projectId, mrId, {
      state_event: "close",
    });
    if (mr.state === "closed") {
      console.log(`Merge request '${mrId}' closed successfully.`);
      return "success";
    }
    return "error";
  } catch (error) {
    console.error(`Failed to close merge request '${mrId}': ${error}`);
    return "error";
  }
};

export const GetHeadPipelineId = async (
  gitlab: any,
  projectId: number,
  mergeRequestId: number,
  timeoutSeconds: number,
  waitForSuccess: boolean = true,
) => {
  const retries = timeoutSeconds;
  let pipeline = null;

  for (let i = 0; i < retries; i++) {
    console.log(
      `Attempt ${i + 1} to get head pipeline for merge request ${mergeRequestId}`,
    );
    try {
      pipeline = (await gitlab.MergeRequests.show(projectId, mergeRequestId))
        .head_pipeline;
    } catch (error) {
      console.error(
        `Failed to get head pipeline for merge request ${mergeRequestId}: ${error}`,
      );
    }
    if (pipeline && (!waitForSuccess || pipeline.status === "success")) {
      return {
        pipelineId: pipeline.id,
        pipelineLink: pipeline.web_url,
        pipelineStatus: pipeline.status,
      };
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }

  return {
    pipelineId: pipeline.id,
    pipelineLink: pipeline.web_url,
    pipelineStatus: pipeline.status,
  };
};

export const MergeMergeRequest = async (
  gitlab: any,
  projectId: number,
  mergeRequestId: number,
  timeoutSeconds: number,
) => {
  const retries = timeoutSeconds;
  let mr = null;

  try {
    await gitlab.MergeRequests.merge(projectId, mergeRequestId, {
      squash: true,
      shouldRemoveSourceBranch: true,
    });
  } catch (error) {
    console.error(`Failed to merge merge request ${mergeRequestId}: ${error}`);
  }

  for (let i = 0; i < retries; i++) {
    console.log(`Attempt ${i + 1} to merge merge request ${mergeRequestId}`);
    try {
      mr = await gitlab.MergeRequests.show(projectId, mergeRequestId);
    } catch (error) {
      console.error(`Failed to get merge request ${mergeRequestId}: ${error}`);
    }
    if (mr.state === "merged") {
      console.log(`Merge request ${mergeRequestId} merged successfully.`);
      return {
        id: mr.id,
        link: mr.web_url,
        status: mr.state,
      };
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }
  return {
    id: mr.id,
    link: mr.web_url,
    status: mr.state,
  };
};

export const WaitForConverge = async (
  gitlab: any,
  projectId: number,
  timeoutSeconds: number,
) => {
  await new Promise((resolve) => setTimeout(resolve, 1500));

  const retries = timeoutSeconds;
  let jobs = [];

  for (let i = 0; i < retries; i++) {
    console.log(`Attempt ${i + 1} to get jobs for project ${projectId}`);
    try {
      jobs = await gitlab.Jobs.all(projectId, { scope: "created" });
      jobs.push(...(await gitlab.Jobs.all(projectId, { scope: "pending" })));
    } catch (error) {
      console.error(`Failed to get jobs for project ${projectId}: ${error}`);
    }
    const filteredJobs = jobs.filter((job: any) =>
      job.name.toLowerCase().includes("converge"),
    );
    if (filteredJobs.length === 0) {
      console.log(`No running pipelines found for project ${projectId}`);
      return "success";
    } else {
      console.log(
        `Running converge job found ${filteredJobs[0].id} for project ${projectId}`,
      );
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }
  console.log("Converge jobs very likley stuck. Please check the pipeline.");
  return { jobName: "", jobId: -1 };
};

export const GetManualJobsWithKeyword = async (
  gitlab: any,
  projectId: number,
  keyword: string,
  timeoutSeconds: number,
) => {
  const retries = timeoutSeconds;

  for (let i = 0; i < retries; i++) {
    console.log(`Attempt ${i + 1} to get manual jobs for project ${projectId}`);
    let filteredJobs = [];
    try {
      const jobs = await gitlab.Jobs.all(projectId, { scope: "manual" });
      filteredJobs = jobs.filter((job: any) => job.name === keyword);
      filteredJobs.sort((a: any, b: any) => {
        if (a.created_at && b.created_at) {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        } else if (!a.created_at && !b.created_at) {
          return 0;
        } else if (!a.created_at) {
          return -1;
        } else {
          return 1;
        }
      });
    } catch (error) {
      console.error(
        `Failed to get manual jobs for project ${projectId}: ${error}`,
      );
    }

    if (filteredJobs.length === 0) {
      console.log(`No manual jobs found with keyword '${keyword}'.`);
    }
    if (filteredJobs.length > 0) {
      const newestJob = filteredJobs[0];
      console.log(`Manual job found with keyword '${keyword}'.`);
      return { jobName: newestJob.name, jobId: newestJob.id };
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }
  console.log("Manual jobs very likley stuck. Please check the pipeline.");
  return { jobName: "", jobId: -1 };
};

export const PlayJob = async (
  gitlab: any,
  projectId: number,
  jobId: number,
) => {
  try {
    await gitlab.Jobs.play(projectId, jobId);
    console.log(`Job '${jobId}' started successfully.`);

    let activeJob = await gitlab.Jobs.show(projectId, jobId);
    if (!activeJob) {
      console.log(`Job '${jobId}' not found.`);
    }
    let status = activeJob.status;
    while (status !== "success" && status !== "failed") {
      await new Promise((resolve) => setTimeout(resolve, 1500));
      console.log(`Job '${jobId}' status: ${status}`);
      activeJob = await gitlab.Jobs.show(projectId, jobId);
      status = activeJob.status;
    }

    return status;
  } catch (error) {
    console.error(`Failed to play job '${jobId}': ${error}`);
    return "error";
  }
};

// Function to cancel a pipeline
export const CancelPipeline = async (
  gitlab: any,
  projectId: number,
  pipelineId: number,
): Promise<"success" | "error"> => {
  try {
    const response = await gitlab.Pipelines.cancel(projectId, pipelineId);
    if (response.status === "canceled") {
      return "success";
    }
    return "error";
  } catch (error) {
    console.error(`Failed to cancel pipeline '${pipelineId}': ${error}`);
    return "error";
  }
};
