import { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import UploadIcon from "@mui/icons-material/Upload";
import useStyles from "./TeamInsightsCardStyles";
import { teamsData } from "./teamsData";
import { ScoreBadge } from "../../shared/ScoreBadge";
import { CustomDataGrid } from "../../shared/CustomDataGrid/CustomDataGrid";

export const TeamInsightsCard = () => {
  const classes = useStyles();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const calculateScore = (team: any) => {
    const meanScore =
      (team.productionReadiness + team.monitoringMaturity + team.doraMetrics) /
      3;
    return Math.ceil(meanScore);
  };

  const columns = [
    {
      field: "name",
      headerName: "TEAM",
      flex: 1,
      renderCell: (params: any) => (
        <Box className={classes.titleBox} display="flex" alignItems="center">
          <img
            className={classes.avatar}
            src="/png/vf-icon.png"
            alt={`${params.row.name} icon`}
          />
          <Typography className={classes.cellText}>{params.value}</Typography>
        </Box>
      ),
    },
    {
      field: "productionReadiness",
      headerName: "PRODUCTION READINESS",
      flex: 1,
      renderCell: (params: any) => (
        <LinearProgress
          variant="determinate"
          value={params.value}
          className={classes.progressBar}
          classes={{ bar: classes.productionReadinessColor }}
        />
      ),
    },
    {
      field: "monitoringMaturity",
      headerName: "MONITORING MATURITY",
      flex: 1,
      renderCell: (params: any) => (
        <LinearProgress
          variant="determinate"
          value={params.value}
          className={classes.progressBar}
          classes={{ bar: classes.monitoringMaturityColor }}
        />
      ),
    },
    {
      field: "doraMetrics",
      headerName: "DORA METRICS",
      flex: 1,
      renderCell: (params: any) => (
        <LinearProgress
          variant="determinate"
          value={params.value}
          className={classes.progressBar}
          classes={{ bar: classes.doraMetricsColor }}
        />
      ),
    },
    {
      field: "score",
      headerName: "SCORE",
      flex: 0.5,
      renderCell: (params: any) => (
        <ScoreBadge score={params.value} size="medium" />
      ),
    },
  ];

  const rows = teamsData.map((team) => ({
    ...team,
    score: calculateScore(team),
  }));

  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <UploadIcon className={classes.rankIcon} />
          <Typography variant="h5">Team Insights</Typography>
        </Box>
        <CustomDataGrid
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </CardContent>
    </Card>
  );
};
