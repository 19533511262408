import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "white",
    size: 5,
  },
  button: {
    "&:hover": {
      background:
        theme.palette.navigation.navItem?.hoverBackground || "#404040",
    },
  },
}));

export default useStyles;
