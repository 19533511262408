import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === "dark";
  return {
    dataGridRoot: {
      "& .MuiDataGrid-cell": {
        display: "flex",
        alignItems: "center",
        color: isDarkMode ? "#FFFFFF" : "#000000",
      },
      "& .MuiDataGrid-columnHeaders": {
        backgroundColor: isDarkMode ? "#333333" : "#F5F5F5",
        color: isDarkMode ? "#FFFFFF" : "#000000",
      },
      "& .MuiDataGrid-footerContainer": {
        display: "flex",
        justifyContent: "center",
        backgroundColor: isDarkMode ? "#444444" : "#FAFAFA",
        color: isDarkMode ? "#BBBBBB" : "#888888",
        borderTop: `1px solid ${isDarkMode ? "#555555" : "#E0E0E0"}`,
      },
      borderRadius: "14px",
    },
  };
});

export default useStyles;
