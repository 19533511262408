import { GithubRepoNameState } from "./types";

import {
  types,
  domains,
  cloudProviders,
  languages,
  manifestTypes,
} from "./types";

const shortProjectNames: { [id: string]: string } = {
  "Solutions Hub": "solhub",
  "Unified Cloud Communication": "ucc",
  "Solutions Foundry": "solfo",
  "Enhanced Service Monitoring": "esm",
  "Harlock and Yuki": "hlayk",
  "UC Hub": "uch",
  "Cloud Platform": "clp",
  Unity: "unity",
  "Ready Networks": "rn",
  "Interactive Voice": "iv",
  "Quality Assurance": "qa",
};

export const generateName = (props: GithubRepoNameState): string => {
  const {
    projectName,
    type,
    domain,
    language,
    cloudProvider,
    componentName,
    manifestType,
  } = props;

  const shortedName = shortProjectNames[projectName as string];

  return [
    shortedName,
    domain,
    language,
    cloudProvider,
    componentName,
    type,
    manifestType,
  ]
    .filter((field) => field !== undefined && field?.trim() !== "")
    .join("-");
};

export const parseName = (name: string): GithubRepoNameState => {
  let projectName = "";
  let domain = "";
  let language = "";
  let cloudProvider = "";
  let componentName = "";
  let type = "";
  let manifestType;
  let parts = [""];

  try {
    parts = name.split("-");
  } catch {
    parts = [""];
  }

  if (parts.length !== 1 && parts[0] !== "") {
    let i = 0;
    const len = parts.length;

    for (const key in shortProjectNames) {
      if (shortProjectNames[key] === parts[i]) {
        projectName = key;
        i++;
        break;
      }
    }

    if (domains.includes(parts[i])) {
      domain = parts[i];
      i++;
    }

    if (languages.includes(parts[i])) {
      language = parts[i];
      i++;
    }

    if (cloudProviders.includes(parts[i])) {
      cloudProvider = parts[i];
      i++;
    }

    if (i < len && parts[i] !== "") {
      componentName = parts[i];
      i++;
    }

    if (types.includes(parts[i])) {
      type = parts[i];
      i++;
    }

    if (manifestTypes.includes(parts[i])) {
      manifestType = parts[i];
      i++;
    }
  }

  return {
    projectName,
    domain,
    language,
    cloudProvider,
    componentName,
    type,
    manifestType,
  };
};
