export const projectNames = [
  "Solutions Hub",
  "Unified Cloud Communication",
  "Solutions Foundry",
  "Enhanced Service Monitoring",
  "Harlock and Yuki",
  "UC Hub",
  "Cloud Platform",
  "Unity",
  "Ready Networks",
  "Interactive Voice",
  "Quality Assurance",
  "Data Factory",
];

export const types = [
  "app",
  "frontend",
  "backend",
  "lib",
  "common",
  "util",
  "script",
  "doc",
  "iac",
  "integration",
  "client",
  "template",
  "spec",
  "config",
  "tool",
  "poc",
  "policy",
  "sdk",
  "plugin",
  "test",
  "design-asset",
  "design-system",
];

export const domains = ["uc", "fc", "internal", "external", "avia", "mpn"];
export const languages = [
  "java",
  "python",
  "node",
  "react",
  "go",
  "ruby",
  "tf",
];
export const cloudProviders = ["aws", "gcp", "azure", "oci"];
export const manifestTypes = ["k8s", "charts", "istio", "kust", "argo"];

export type GithubRepoNameState = {
  componentName?: string;
  projectName?: string;
  type?: string;
  domain?: string;
  language?: string;
  cloudProvider?: string;
  manifestType?: string;
};

export type BaseGithubRepoNameProps = {
  onChange: (state: GithubRepoNameState) => void;
  state: GithubRepoNameState;
  rawErrors: string[];
};
