import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  popup: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: "16px",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.15)",
    maxWidth: "420px",
    textAlign: "center",
    position: "relative",
    animation: "$fadeIn 0.3s ease-out",
    transition: "transform 0.3s ease-out, opacity 0.3s ease-out",
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0px",
    width: "100%",
  },
  closeButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    color: theme.palette.grey[500],
    padding: "4px",
    borderRadius: "50%",
    transition: "background 0.2s ease",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  iconSuccess: {
    color: "green",
    marginBottom: theme.spacing(1),
  },
  iconError: {
    color: "red",
    marginBottom: theme.spacing(1),
  },

  title: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontSize: "0.9rem",
    marginBottom: theme.spacing(1),
    flexGrow: 1,
  },
  ticketLink: {
    color: "#007bff",
    textDecoration: "none",
    transition: "color 0.3s, text-decoration 0.3s",
    "&:hover": {
      color: "#0056b3",
      textDecoration: "underline",
    },
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
      transform: "scale(0.9)",
    },
    "100%": {
      opacity: 1,
      transform: "scale(1)",
    },
  },
}));

export default useStyles;
