import { scaffolderPlugin } from "@backstage/plugin-scaffolder";
import { createScaffolderFieldExtension } from "@backstage/plugin-scaffolder-react";
import { GithubRepoNaming } from "./GithubNamingExtension";
import { GithubRepoNamingSchema } from "./schema";

export const ValidateGithubNameFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: "GithubRepoNamingPicker",
    component: GithubRepoNaming,
    schema: GithubRepoNamingSchema,
  }),
);
