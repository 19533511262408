import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  card: {
    cursor: "pointer",
    boxShadow: (props: { isSelected: boolean }) =>
      props.isSelected ? "0 0 10px rgba(0, 0, 0, 0.2)" : "none",
    transition: "box-shadow 0.3s ease",
    width: "100px",
  },
  cardMedia: {
    height: "50px",
    objectFit: "contain",
    margin: "auto",
  },
});
