import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  floatButton: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    backgroundColor: theme.palette.type === "dark" ? "#000" : "#000", // Black button in both modes
    color: "#fff",
    border: "none",
    borderRadius: "50%",
    width: "48px",
    height: "48px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)", // Subtle shadow effect
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: theme.palette.type === "dark" ? "#cc0000" : "#cc0000", // Red hover effect in both modes
    },
  },
  modal: {
    position: "absolute",
    bottom: "80px",
    right: "40px",
    width: "250px",
    padding: "15px",
    borderRadius: "12px",
    backgroundColor: theme.palette.type === "dark" ? "#2c2c2e" : "#fff", // Dark: dark gray, Light: white
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.6)", // Shadow effect
    border: theme.palette.type === "dark" ? "1px solid #444" : "1px solid #ddd", // Dark: dark border, Light: lighter
    color: theme.palette.type === "dark" ? "#fff" : "#000", // Text color: light text in dark mode, dark text in light mode
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "6px", // Updated gap for better spacing
  },
  input: {
    width: "100%",
    padding: "2px",
    border: `1px solid ${theme.palette.divider}`, // Use theme divider color for the border
    borderRadius: "6px",
    backgroundColor: theme.palette.type === "dark" ? "#3c3c3c" : "#f9f9f9", // Dark: dark gray background, Light: white
    boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.05)",
    transition: "border-color 0.3s",

    "&:focus": {
      borderColor: "#e60000", // Red border on focus for both modes
      outline: "none",
    },
  },
  textArea: {
    width: "100%",
    padding: "10px",
    border: `1px solid ${theme.palette.divider}`, // Use theme divider color
    borderRadius: "6px",
    backgroundColor: theme.palette.type === "dark" ? "#3c3c3c" : "#f9f9f9", // Same background as input
    boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.05)",
    transition: "border-color 0.3s",
    resize: "vertical",
    minHeight: "150px",
    marginBottom: "8px",

    "&:focus": {
      borderColor: "#e60000", // Red border on focus
      outline: "none",
    },
  },
  actionButton: {
    backgroundColor: theme.palette.type === "dark" ? "#ff453a" : "#e60000", // Red button in both modes
    color: "#fff",
    padding: "6px",
    border: "none",
    borderRadius: "6px",
    cursor: "pointer",
    textAlign: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.type === "dark" ? "#cc0000" : "#cc0000", // Darker red on hover for both modes
    },
  },
  successMessage: {
    color: "green",
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
  },
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
  },
}));

export default useStyles;
