import { makeStyles } from "@material-ui/core/styles";

const useToolbarStyles = makeStyles(
  (theme) => ({
    root: {
      paddingTop: theme.spacing(1.25),
      paddingLeft: theme.spacing(2.5),
      paddingBottom: theme.spacing(0.75),
      display: "flex",
      justifyContent: "space-between",
    },
    text: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  }),
  { name: "BackstageTableToolbar" },
);

export default useToolbarStyles;
