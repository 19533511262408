import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  infoCard: {
    marginBottom: theme.spacing(3),
    "& + .MuiAlert-root": {
      marginTop: theme.spacing(3),
    },
  },
  subTitle: {
    marginTop: theme.spacing(0),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
