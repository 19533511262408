import { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  MenuItem,
  TextField,
  Select,
  Box,
  Typography,
} from "@mui/material";

interface Variable {
  label: string;
  variable_name: string;
  description: string;
  type: string;
  required: boolean;
  allowed_values?: string[];
  default?: string;
  properties?: Variable[];
}

type VariableInputFieldProps = {
  variable: Variable;
  setVariable: (name: string, value: string, parent?: string) => void;
  parent?: string;
};

export default function VariableInputField(props: VariableInputFieldProps) {
  const [selectedValue, setSelectedValue] = useState(
    props.variable.default ? props.variable.default : "",
  );

  const parentPath = useRef<string | undefined>(props.parent);

  const textFieldStyle = {
    "& input": {
      padding: "2px",
      textAlign: "center",
      width: "200px",
    },
    "& div": {
      padding: "2px",
      textAlign: "center",
    },
    width: "200px",
  };

  const onChange = (event: any) => {
    props.setVariable(
      props.variable.variable_name,
      event.target.value,
      parentPath.current,
    );
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (props.variable.default !== undefined) {
      props.setVariable(
        props.variable.variable_name,
        props.variable.default,
        parentPath.current,
      );
      setSelectedValue(props.variable.default);
    }
  }, []);

  return (
    <>
      {props.variable.type === "object" && props.variable.properties && (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {props.variable.properties.map((property: Variable) => {
            parentPath.current = props.parent
              ? `${props.parent}.${props.variable.variable_name}`
              : props.variable.variable_name;
            console.log("parentPath: ", parentPath.current);
            return (
              <VariableInputField
                key={property.variable_name}
                variable={property}
                setVariable={props.setVariable}
                parent={parentPath.current}
              />
            );
          })}
        </div>
      )}
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
        mb={1}
      >
        {props.variable.type !== "object" && (
          <Typography variant="body1" color="primary">
            {props.variable.label}
          </Typography>
        )}
        <Box display={"flex"}>
          {props.variable.type === "string" && (
            <TextField
              variant="outlined"
              id={props.variable.variable_name}
              key={props.variable.variable_name}
              fullWidth
              sx={textFieldStyle}
              required={props.variable.required}
              onChange={onChange}
              value={selectedValue}
              placeholder="Enter value"
            />
          )}
          {props.variable.type === "bool" && (
            <Checkbox
              id={props.variable.variable_name}
              onChange={() => {
                const value = !selectedValue;
                onChange({ target: { value } });
              }}
              checked={String(selectedValue) === "true" ? true : false}
              sx={{ margin: "auto" }}
            />
          )}
          {props.variable.type === "number" && (
            <TextField
              type="number"
              variant="outlined"
              id={props.variable.variable_name}
              key={props.variable.variable_name}
              fullWidth
              sx={textFieldStyle}
              required={props.variable.required}
              onChange={onChange}
              value={selectedValue}
              placeholder="Set value"
            />
          )}
          {props.variable.type === "select" &&
            props.variable.allowed_values && (
              <>
                <Select
                  value={selectedValue}
                  onChange={onChange}
                  sx={textFieldStyle}
                  variant="outlined"
                  id={props.variable.variable_name}
                  key={props.variable.variable_name}
                  required={props.variable.required}
                  fullWidth
                >
                  {props.variable.allowed_values.map((allowed_value) => (
                    <MenuItem key={allowed_value} value={allowed_value}>
                      {allowed_value}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
        </Box>
      </Box>
    </>
  );
}
